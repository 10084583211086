/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  api: 'https://chat-api-new-pp.contactdve.com/api',
  chat: 'https://pp-chat-liveengine.contactdve.com/',

  waitingAPI: 'dating-notification-pp.dv-content.io/',
  waitingToken: 'TtD4dFtMCfu92eCK',
  gtm: 'GTM-TGQ4KT',
  gtmCountry: 'GTM-TWV78ZS',
  completeProfileInputs: ['phoneNumber'],
  id: 'ck5zhqim9terh0b62i6lafqxa',
  dvp4m: true,
  gsv: null,
  appLink: 'https://www.goswipi.com/redirect_stores',
  didomi: null,
};
